import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'

const IndexPage = () => {
  return (
	<Layout>
		<main>
			<section id="hero">
				<h1>Sitekeeper Template</h1>
			</section>
			<section id="intro">
				<p>This is a starter template.</p>
			</section>
		</main>
	</Layout>
  )
}
export default IndexPage

export const Head = () => (
	<SEO />
)
